import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Slider from "react-slick";
import NoData from '../Common/NoData';
import { Styles } from "./styles/event.js";
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../../Config/FbConfig';

export default class Events extends Component {

    state = {
        Datas: [],
        Loading: true
    }

    //Load Data Here
    async componentDidMount() {
        const docRef = doc(firestore, "Home", "Events");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            var Data = []
            Object.entries(docSnap.data()).forEach(([key, value]) => {
                Data.push(Object.assign({ Id: key }, value))
            });
            this.setState({
                Datas: Data,
                Loading: false
            })
        } else {
            this.setState({
                Datas: "NO_999",
                Loading: false
            })
        }
    }

    render() {

        var slick_slider = {
            dots: true,
            arrow: true,
            autoplay: true,
            autoplaySpeed: 2500,
            speed: 2000,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1199,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                    },
                },
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                    },
                },
                {
                    breakpoint: 680,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    },
                },
                {
                    breakpoint: 575,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    },
                },
            ],
        };

        return (
            <Styles>
                <section className="event-area">
                    <Container>
                        <Row>
                            <Col md="5">
                                <div className="section-title style3 mb-0 clearfix">
                                    <div className="title-header mb-0">
                                        <h2 className="title ttm-textcolor-white"> <span className="ttm-textcolor-white" style={{ color: "#0f6464" }}>Events @ VIPS</span></h2>
                                    </div>
                                </div>
                            </Col>
                            <Col md="7" style={{ marginTop: "1rem" }}><hr /></Col>
                            <Col md="12" className="team-slider" style={{ paddingTop: "4rem" }}>
                                <Slider className="row" {...slick_slider}>
                                    {this.state.Datas === undefined ? (<NoData />) : (
                                        this.state.Datas.length &&
                                        this.state.Datas.map(function (item, index) {
                                            return (
                                                <div key={index}>
                                                    <div className="col-lg-12 col-md-4">
                                                        <div className="featured-icon-box style5 text-left res-767-mb-20">
                                                            <div className="featured-content">
                                                                <a target="_blank" href={item.Image}><img src={item.Image} alt="" className="img-fluid" /></a><br/>
                                                                <p className='justify'><br />{item.Description}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })
                                    )}
                                </Slider>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Styles>
        )
    }
}
 