import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyCnm4AwbZwWe7jYaFMFvPXkOg_xGRXZ_7A",
  authDomain: "rvs-pharmacy.firebaseapp.com",
  projectId: "rvs-pharmacy",
  storageBucket: "rvs-pharmacy.appspot.com",
  appId: "1:277871957807:web:3cfe5ec3d67500ea8df3da",
  measurementId: "G-K1D4GR7WJK"
};

const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);

export {auth, firestore , storage};