import React, { Component } from 'react'
import { Menu } from './menu';
import Mobilemenu from './Mobile_menu';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../../Config/FbConfig';

export class Header extends Component {

    state = {
        show: false,
        Datas: [],
        Loading: true
    }

    //Load Data Here
    async componentDidMount() {
        const docRef = doc(firestore, "Home", "ImportantNotification");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            var Data = []
            Object.entries(docSnap.data()).forEach(([key, value]) => {
                Data.push(Object.assign({ Id: key }, value))
            });
            this.setState({
                Datas: Data,
                Loading: false
            })
        } else {
            this.setState({
                Datas: "NO_999",
                Loading: false
            })
        }
    }

    toggle = () => this.setState((currentState) => ({ show: !currentState.show }));
    
    render() {

        return (
            <header id="masthead" className="header ttm-header-style-01">
                {/* top scroll start*/}
                <div className="ttm-topbar-wrapper ttm-bgcolor-darkgrey ttm-textcolor-white clearfix">
                    <ul className="top-contact text-left">
                        <div class="bar">
                            <marquee class="example1" direction="left" scrollamount="3" behavior="scroll">
                                {
                                    this.state.Datas.length === 0 ? "Loading..." :
                                        this.state.Datas === "NO_999" ? "No Data" :
                                            this.state.Datas.map((data, i) => (
                                                <a key={i} target="_blank" href={data.Content}>{data.Header}&nbsp;&nbsp;</a>
                                            ))
                                }
                            </marquee>
                        </div>
                    </ul>
                </div>
                {/* top scroll end*/}
                {/* Top Menu - Start  */}
                <div className="ttm-header-wrap">
                    <div id="navbar" className="ttm-stickable-header clearfix">
                        <div className="site-header-menu">
                            <div className="container-xl">
                                <div className="site-branding">
                                    <a className="home-link" href={process.env.PUBLIC_URL + '/'} title="Altech" rel="home">
                                        <img id="logo-img" className="img-fluid hide-on-mob" alt="logo" src="images/logo.png" />
                                        <img id="logo-img" className="img-fluid show-on-mob" alt="logo" src="images/logo.png" style={{ marginLeft: "10rem", marginTop: "1.5rem" }} />
                                    </a>
                                </div>
                                <div className="site-navigation">
                                    <div className="ttm-right-header"></div>
                                    <Menu />
                                    <div className="mobilemenu"><Mobilemenu /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Top Menu - End  */}
            </header>
        )
    }
}

export default Header;