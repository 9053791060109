import React, { Component } from 'react'
import Header from '../layout/Header';
import Banner from '../Common/Banner';

export default class AboutRules extends Component {

    render() {
        return (
            <div className="body_wrapper">
                <Header />
                <Banner title="Rules and Regulations" image="banners/About.jpg" />

                {/* Content */}
                <section className="ttm-row service-section-style2 res-991-pb-0 ttm-bgcolor-grey clearfix">
                    <div className="container-xl">
                        <div className="row no-gutters">
                            <div className="col-md-6 col-lg-6">
                                <div className="featured-icon-box style6">
                                    <div className="featured-content">
                                        <div className="featured-title"><h5>C and R Rules</h5></div>
                                        <div className="featured-desc">
                                            <p className="text-justify"> The Draft of the Vokkaligara Sangha Dental College and Hospital(Cadre & Recruitment) Rules,2019, which the Administrator of the Rajya Vokkaligara Sangha,appointed by the State Government Under Section (27A) of Karnataka Society Registeration Act.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6">
                                <div className="featured-icon-box style6">
                                    <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                                        <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" /><b>C & R - 1 : </b><a href={process.env.PUBLIC_URL + "/assets/images/aboutrules/kips-eng-rules.pdf"} target="_blank" rel="noopener noreferrer" download>Download PDF</a></li>
                                        <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" /><b>C & R - 2 : </b><a href={process.env.PUBLIC_URL + "/assets/images/aboutrules/Vips-eng-sc1.pdf"} target="_blank" rel="noopener noreferrer" download>Download PDF</a></li>
                                        <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" /><b>C & R - 3 : </b><a href={process.env.PUBLIC_URL + "/assets/images/aboutrules/kips-eng-sc2.pdf"} target="_blank" rel="noopener noreferrer" download>Download PDF</a></li>
                                        <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" /><b>C & R - 4 : </b><a href={process.env.PUBLIC_URL + "/assets/images/aboutrules/kips-eng-sc3.pdf"} target="_blank" rel="noopener noreferrer" download>Download PDF</a></li>
                                        <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" /><b>C & R - 5 : </b><a href={process.env.PUBLIC_URL + "/assets/images/aboutrules/kips-eng-sc4.pdf"} target="_blank" rel="noopener noreferrer" download>Download PDF</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-12"><br /></div>
                            <div className="col-md-6 col-lg-6">
                                <div className="featured-icon-box style6">
                                    <div className="featured-content">
                                        <div className="featured-title"><h5>PCI Rules and Regulations</h5></div>
                                        <div className="featured-desc">
                                            <p className="text-justify">Expertise in a IT consultancy for the many companies, for their different working areas.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6">
                                <div className="featured-icon-box style6">
                                    <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                                        <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" /><b>PCI Rules - 1 : </b><a href={process.env.PUBLIC_URL + "/assets/images/aboutrules/Diploma in Pharmacy Exit Examination Regulations, 2022.pdf"} target="_blank" rel="noopener noreferrer" download>Download PDF</a></li>
                                        <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" /><b>PCI Rules - 2 : </b><a href={process.env.PUBLIC_URL + "/assets/images/aboutrules/DPharm Education_REGULATION_2020_12022021.pdf"} target="_blank" rel="noopener noreferrer" download>Download PDF</a></li>
                                        <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" /><b>PCI Rules - 3 : </b><a href={process.env.PUBLIC_URL + "/assets/images/aboutrules/Regulation for salary fixation (Nov 2014).pdf"} target="_blank" rel="noopener noreferrer" download>Download PDF</a></li>
                                        <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" /><b>PCI Rules - 4 : </b><a href={process.env.PUBLIC_URL + "/assets/images/aboutrules/Pharmacy Practice (Amendment) Regulations, 2021.pdf"} target="_blank" rel="noopener noreferrer" download>Download PDF</a></li>
                                        <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" /><b>PCI Rules - 5 : </b><a href={process.env.PUBLIC_URL + "/assets/images/aboutrules/PharmD_regu_2008 84-87 page.pdf"} target="_blank" rel="noopener noreferrer" download>Download PDF</a></li>
                                        <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" /><b>PCI Rules - 6 : </b><a href={process.env.PUBLIC_URL + "/assets/images/aboutrules/Pharmacy Practice Regulations.pdf"} target="_blank" rel="noopener noreferrer" download>Download PDF</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-12"><br /></div>
                            <div className="col-md-6 col-lg-6">
                                <div className="featured-icon-box style6">
                                    <div className="featured-content">
                                        <div className="featured-title"><h5>Rajiv Gandhi University of Health Sciences (RGUHS) Curriculum</h5></div>
                                        <div className="featured-desc">
                                            <p className="text-justify">We develop, migrate &amp; work on applications to ensure that run capably on cloud.</p>
                                        </div>
                                    </div>
                                    <br />
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6">
                                <div className="featured-icon-box style6">
                                    <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                                        <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" /><b>RGUHS Rules - 1 : </b><a href={process.env.PUBLIC_URL + "/assets/images/aboutrules/B.pharm Syllabus received from GCP on 09-02-2022 RGUHS.pdf"} target="_blank" rel="noopener noreferrer" download>Download PDF</a></li>
                                        <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" /><b>RGUHS Rules - 2 : </b><a href={process.env.PUBLIC_URL + "/assets/images/aboutrules/PharmD Regulations edited January 19 2017(Latest).pdf"} target="_blank" rel="noopener noreferrer" download>Download PDF</a></li>
                                        <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" /><b>RGUHS Rules - 3 : </b><a href={process.env.PUBLIC_URL + "/assets/images/aboutrules/MPharm 10-05-2017 semster (RGUHS).pdf"} target="_blank" rel="noopener noreferrer" download>Download PDF</a></li>
                                        <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" /><b>RGUHS Rules - 4 : </b><a href={process.env.PUBLIC_URL + "/assets/images/aboutrules/PharmD-PB Revised Effective from 2012-2013 (RGUHS).pdf"} target="_blank" rel="noopener noreferrer" download>Download PDF</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <br />
            </div>
        )
    }
}
