import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';


export class DepartmentSidebar extends Component {
    render() {
        return (
            <div>
                <div className="widget widget-nav-menu">
                    <ul className="widget-menu">
                        <li><NavLink to={process.env.PUBLIC_URL + '/Departments-Pharmaceutics'}>Pharmaceutics</NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + '/Departments-Pharmacology'}>Pharmacology</NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + '/Departments-Pharmaceutical-Chemistry'}>Pharmaceutical Chemistry</NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + '/Departments-Pharmacognosy'}>Pharmacognosy</NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + '/Departments-Pharmacy-Practice'}>Pharmacy Practice</NavLink></li>
                    </ul>
                </div>
            </div>
        );
    }
}

export default DepartmentSidebar;