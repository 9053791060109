import React, { Component } from 'react'
import Header from '../layout/Header';
import Banner from '../Common/Banner';
import ProgrammeSidebar from './ProgrammeSidebar';

export default class ProgramsBPharm extends Component {

    render() {
        return (
            <div className="body_wrapper">
                <Header />
                <Banner title="Programme" image="banners/Programme.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="ttm-service-single-content-area">
                                    <div className="section-title with-desc text-left clearfix">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">B. Pharm Course</h4>
                                        </div>
                                    </div>
                                    <div className="section-title with-desc text-left clearfix">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h6 className="title">Eligibility</h6>
                                        </div>
                                    </div>
                                    <p className="justify">Candidates should have passed 10+2 examination with Physics and Chemistry as compulsory subjects along with one of the following - Mathematics / Computer Science / Biotechnology / Biology. Obtained at least 40% marks in the above subjects taken together. Candidates who have passed D.Pharm course approved by Pharmacy Council of India shall also be eligible to this course and can be admitted directly to II Year.</p>
                                    <b>Original Documents and 3 sets of photocopies of all documents required at the time of Admission for B.Pharm course</b>
                                    <div className="row paddingtop-1">
                                        <div className="col-lg-6">
                                            <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />P.U.C Markscard / 12th  Markscard</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />SSLC / 10th  Markscard</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Address proof</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Transfer certificate</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Aadhar Card</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Study and Conduct Certificate</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Color passport size photograph with name – 4 nos</li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-6">
                                            <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Parent’s PAN Card</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Caste and Income Certificate</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Migration certificate from the former University/Board</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Eligibility certificate from Rajiv Gandhi University of Health Sciences for CBSE/ICSE/Non-Karnataka/NRI/Foreigners</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Passport, Visa and Residential permit from Police Commissioner, Bangalore for NRI/Foreigners</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="section-title with-desc text-left clearfix paddingtop-2">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h6 className="title">Career opportunity in B.Pharma</h6>
                                        </div>
                                    </div>
                                    <h6 className="title">Private Jobs</h6>
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Clinical Pharmacist</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Hospital Pharmacy Director</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Hospital Staff Pharmacist</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Pharmacist</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Drug Technician</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Data Analyst</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Regulatory affairs</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Primary school teacher</li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-4">
                                            <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Pharmacist in Charge</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Lecturer/Assistant Professor</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Sales and Marketing Executive</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Retail Pharmacist</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Business Development Manager</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Research Scientist</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Lecturer</li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-4">
                                            <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Retail Staff Pharmacist</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Pharmacologist</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Clinical Research Associate (CRA)</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Junior Clinical Research Associate (CRA)</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Medical Writer</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <h6 className="title paddingtop-2">Government Jobs</h6>
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Analytical Chemist- Pathological Scientist</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Food and Drug Inspector - Research Officer</li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-4">
                                            <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Hospital Drug Coordinator- Pharmacist</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Drug Therapist- Health Inspector</li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-4">
                                            <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Chemical/Drug Technician -Drug Therapist</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <ProgrammeSidebar />
                            </div>
                        </div>
                    </div>
                </div>
                <br />
            </div>
        )
    }
}

