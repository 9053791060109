import React, { Component } from 'react'
import Header from '../layout/Header';
import Banner from '../Common/Banner';
import FacilitiesSidebar from './FacilitiesSidebar';

export default class AboutScholarship extends Component {

    render() {
        return (
            <div className="body_wrapper">
                <Header />
                <Banner title="Facilities" image="banners/About.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="ttm-service-single-content-area">
                                    <div className="section-title with-desc text-left clearfix">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">Scholarship</h4>
                                        </div>
                                    </div>
                                    <div className="ttm-service-description">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/aboutfacilities/facilities_6.jpg"} alt="Error" />
                                            </div>
                                            <div className="col-md-7" style={{ margin: "auto" }}>
                                                <p className="text-justify">Students can avail SSP scholarships and Karnataka e-pass scholarships. Many of our students avail these scholarships. Pharmacy scholarships and grants are available every year. Students can utilize these endowments and cover their tuition fees, accommodation and other education related expenses.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <FacilitiesSidebar />
                            </div>
                        </div>
                    </div>
                </div>
                <br />
            </div>
        )
    }
}