import React, { Component } from "react";
import StudentAchivers from "../home/StudentAchivers";
import FacultyAchivers from "../home/FacultyAchivers";
import Events from "../home/Events";

export default class Number extends Component {
  render() {
    return (
      <div>
        {/* About College */}
        <section className="ttm-row zero-padding-section ttm-bgcolor-grey clearfix">
          <div className="container-xl">
            <div className="row no-gutters">
              <div className="col-md-12">
                <div className="ttm-bgcolor-white ttm-bg ttm-col-bgcolor-yes ttm-left-span spacing-7 position-relative z-1">
                  <div className="ttm-col-wrapper-bg-layer ttm-bg-layer">
                    <div className="ttm-bg-layer-inner" />
                  </div>
                  <div className="layer-content">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="ttm_single_image-wrapper">
                          <img
                            className="img-fluid"
                            src={
                              process.env.PUBLIC_URL +
                              `/assets/images/home/Number.jpeg`
                            }
                            title="single-img-five"
                            alt="single-img-five"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="res-991-mt-30">
                          <div className="section-title with-desc clearfix">
                            <div className="title-header">
                              <h5>About VIPS</h5>
                              <h2 className="title">
                                We Do Our Best To Achieve Your Goal. Your
                                Happiness Is Our <span>First Priority</span>
                              </h2>
                            </div>
                            <div className="title-desc">
                              <p>
                                Visveswarapura Institute of Pharmaceutical
                                Sciences, also known as the first private
                                pharmacy college in Bangalore, was founded in
                                1980 by Rajya Vokkaligara Sangha. Our college is
                                affiliated to Rajiv Gandhi University of Health
                                Sciences(RGUHS), Karnataka and is recognized by
                                the Pharmacy Council of India (PCI).
                              </p>
                              <p>
                                Visveswarapura Institute of Pharmaceutical
                                Sciences is one of Bangalore's best pharmacy
                                schools, featuring state-of-the-art laboratory
                                facilities and a well-stocked digital library.
                                Students are taught by the best, highly
                                qualified, and most experienced teaching faculty
                                available. Our college also has separate men's
                                and women's hostels, each with its own set of
                                amenities for boarding and other sporting areas.
                              </p>
                            </div>
                            <a
                              className="ttm-btn ttm-btn-size-md ttm-bgcolor-darkgrey ttm-textcolor-white"
                              href={
                                process.env.PUBLIC_URL +
                                "/assets/images/home/SIF_Report.pdf"
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                              download
                            >
                              View SIF Report Click Here
                            </a>
                            <a
                              className="ttm-btn ttm-btn-size-md ttm-bgcolor-darkgrey ttm-textcolor-white"
                              href={
                                process.env.PUBLIC_URL +
                                "/assets/images/home/VIPS_Info.pdf"
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                              download
                            >
                              VIPS Information Click Here
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Events */}
        <Events />

        {/* Uniqueness */}
        <section className="ttm-row bottomzero-padding-section position-relative clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <div className="section-title text-center with-desc clearfix">
                  <div className="title-header">
                    <h2 className="title">
                      Uniqueness of <span>VIPS</span>
                    </h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="row multi-columns-row ttm-boxes-spacing-10px ttm-bgcolor-white">
              <div className="ttm-box-col-wrapper col-lg-4 col-md-6 col-sm-6">
                <div className="featured-imagebox featured-imagebox-portfolio style2">
                  <div className="featured-thumbnail">
                    <img
                      className="img-fluid"
                      src={
                        process.env.PUBLIC_URL +
                        `/assets/images/home/Unique1.jpg`
                      }
                      alt="image"
                    />
                  </div>
                  <div className="featured-content">
                    <div className="category">
                      <p className="text-justify">
                        A 40 year old, First Private Pharmacy College in
                        Bangalore.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ttm-box-col-wrapper col-lg-4 col-md-6 col-sm-6">
                <div className="featured-imagebox featured-imagebox-portfolio style2">
                  <div className="featured-thumbnail">
                    <img
                      className="img-fluid"
                      src={
                        process.env.PUBLIC_URL +
                        `/assets/images/home/Unique2.jpg`
                      }
                      alt="image"
                    />
                  </div>
                  <div className="featured-content">
                    <div className="category">
                      <p className="text-justify">
                        Umbrella of Rajya Vokkaligara Sangha{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ttm-box-col-wrapper col-lg-4 col-md-6 col-sm-6">
                <div className="featured-imagebox featured-imagebox-portfolio style2">
                  <div className="featured-thumbnail">
                    <img
                      className="img-fluid"
                      src={
                        process.env.PUBLIC_URL +
                        `/assets/images/home/Unique3.jpg`
                      }
                      alt="image"
                    />
                  </div>
                  <div className="featured-content">
                    <div className="category">
                      <p className="text-justify">
                        Holds One in Five Ranking in the State under Rajiv
                        Gandhi University Of Health Sciences.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ttm-box-col-wrapper col-lg-4 col-md-6 col-sm-6">
                <div className="featured-imagebox featured-imagebox-portfolio style2">
                  <div className="featured-thumbnail">
                    <img
                      className="img-fluid"
                      src={
                        process.env.PUBLIC_URL +
                        `/assets/images/home/Unique4.jpg`
                      }
                      alt="image"
                    />
                  </div>
                  <div className="featured-content">
                    <div className="category">
                      <p className="text-justify">
                        College located in City Centre{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ttm-box-col-wrapper col-lg-4 col-md-6 col-sm-6">
                <div className="featured-imagebox featured-imagebox-portfolio style2">
                  <div className="featured-thumbnail">
                    <img
                      className="img-fluid"
                      src={
                        process.env.PUBLIC_URL +
                        `/assets/images/home/Unique5.jpg`
                      }
                      alt="image"
                    />
                  </div>
                  <div className="featured-content">
                    <div className="category">
                      <p className="text-justify">
                        Provides State of Art Infrastructure and Latest
                        Facilities with well-equipped classrooms, laboratories
                        and well stocked Library
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ttm-box-col-wrapper col-lg-4 col-md-6 col-sm-6">
                <div className="featured-imagebox featured-imagebox-portfolio style2">
                  <div className="featured-thumbnail">
                    <img
                      className="img-fluid"
                      src={
                        process.env.PUBLIC_URL +
                        `/assets/images/home/Unique6.jpg`
                      }
                      alt="image"
                    />
                  </div>
                  <div className="featured-content">
                    <div className="category">
                      <p className="text-justify">
                        Highest Retention of faculty among Private Pharmacy
                        Colleges.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/*
                                <div className="ttm-box-col-wrapper col-lg-3 col-md-6 col-sm-6">
                                    <div className="featured-imagebox featured-imagebox-portfolio style2">
                                        <div className="featured-thumbnail">
                                            <img className="img-fluid" src={process.env.PUBLIC_URL + `/assets/images/home/Unique7.jpg`} alt="image" />
                                        </div>
                                        <div className="featured-content">
                                            <div className="category">
                                                <p className="text-justify">Dedicated team of vibrant, innovative, highly qualified academicians.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="ttm-box-col-wrapper col-lg-3 col-md-6 col-sm-6">
                                    <div className="featured-imagebox featured-imagebox-portfolio style2">
                                        <div className="featured-thumbnail">
                                            <img className="img-fluid" src={process.env.PUBLIC_URL + `/assets/images/home/Unique8.jpg`} alt="image" />
                                        </div>
                                        <div className="featured-content">
                                            <div className="category">
                                                <p className="text-justify">Highest Retention of faculty among Private Pharmacy Colleges.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            */}
            </div>
          </div>
        </section>

        {/* Student Achivers */}
        <StudentAchivers />

        {/* Faculty Achivers */}
        <FacultyAchivers />
      </div>
    );
  }
}
