import React, { Component } from 'react'
import Header from '../layout/Header';
import Banner from '../Common/Banner';
import FacilitiesSidebar from './FacilitiesSidebar';

export default class AboutHostel extends Component {

    render() {
        return (
            <div className="body_wrapper">
                <Header />
                <Banner title="Facilities" image="banners/About.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="ttm-service-single-content-area">
                                    <div className="section-title with-desc text-left clearfix">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">Hostel</h4>
                                        </div>
                                    </div>
                                    <div className="ttm-service-description">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/aboutfacilities/facilities_8.jpg"} alt="Error" />
                                            </div>
                                            <div className="col-md-7" style={{ margin: "auto" }}>
                                                <p className="text-justify">Separate Hostels are available for boys and girls in Banashankari 2 stage. It is located behind the college campus. Hostel is supervised by a manager and controlled by warden. Ragging, Contraband substances, alcohol, sound pollution etc are prohibited. Inmates should strictly adhere to the rules and regulations of hostel. Hostel seats will be available as per the</p>
                                            </div>
                                            <div className="col-md-12 paddingtop-1">
                                                <p className="text-justify">vacancies. Our college also has separate men's and women's hostels, each with its own set of amenities for boarding and other sporting areas.</p>
                                            </div>
                                            <div className="col-lg-4 paddingtop-1">
                                                <div className="position-relative res-991-mt-30">
                                                    <div className="ttm_single_image-wrapper text-sm-center">
                                                        <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/aboutfacilities/facilities_8_a.jpg"} alt="single-img-seven" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 paddingtop-1">
                                                <div className="position-relative res-991-mt-30">
                                                    <div className="ttm_single_image-wrapper text-sm-center">
                                                        <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/aboutfacilities/facilities_8_b.jpg"} alt="single-img-seven" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 paddingtop-1">
                                                <div className="position-relative res-991-mt-30">
                                                    <div className="ttm_single_image-wrapper text-sm-center">
                                                        <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/aboutfacilities/facilities_8_c.jpg"} alt="single-img-seven" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <FacilitiesSidebar />
                            </div>
                        </div>
                    </div>
                </div>
                <br />
            </div>
        )
    }
}