import React, { Component } from 'react'
import Header from '../layout/Header';
import Banner from '../Common/Banner';
import DepartmentSidebar from './DepartmentSidebar';
import HodDisplay from './HodDisplay';
import NoData from "../Common/NoData";
import Loading from "../Common/Loading";
import ModalImage from "react-modal-image";
import { firestore } from '../../Config/FbConfig';
import { doc, getDoc } from 'firebase/firestore';


export default class PharmacyPractice extends Component {

    state = { Datas: [] }

    async componentDidMount() {
        //For Teaching
        const docRef = doc(firestore, "FacultiesandStaff", "Teaching");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            var Data = []
            Object.entries(docSnap.data()).forEach(([key, value]) => {
                if (value.Department === "Pharmacy Practice") {
                    Data.push(Object.assign(
                        {
                            Id: key,
                            Priority: value.Priority != undefined ? value.Priority : "999",
                            Image: value.Image,
                            Name: value.Name,
                            Description: value.Description,
                            Designation: value.Designation,
                            Qualification: value.Qualification,
                            Link: value.Link
                        }))
                }
            });

            Data.length === 0 ? this.setState({ Datas: "NO_999" }) : this.setState({ Datas: Data.sort(function (a, b) { return a.Priority - b.Priority; }) })

        }

        else { this.setState({ Datas: "NO_999" }) }
    }

    render() {
        return (
            <div className="body_wrapper">
                <Header />
                <Banner title="Pharmacy Practice" image="departments/PharmacyPractice.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="ttm-service-single-content-area">
                                    <div className="section-title with-desc text-left clearfix">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">About Department</h4>
                                        </div>
                                    </div>
                                    <div className="ttm-service-description">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/departments/department_5.jpg"} alt="Error" />
                                            </div>
                                            <div className="col-md-7" style={{ margin: "auto" }}>
                                                <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                                                    <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />The core subjects in the department are inorganic and organic pharmaceutical chemistry, medicinal chemistry and analytical chemistry.</li>
                                                    <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />The main objective is to develop and evaluate new and better drug for health care aspects and pharmaceutical industries.</li>
                                                </ul>
                                            </div>
                                            <div className="col-md-12 paddingtop-2">
                                                <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                                                    <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Study is concerned with drug discovery, synthesis, chemistry, properties, structure activity relationships and uses of the drug molecules.</li>
                                                    <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Focus is given also on characterization of drugs by analytical and advanced instrumental techniques followed by quality assurance.</li>
                                                    <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />The synthesized new drugs are subjected to various biological activities and to study their structure activity relationships.</li>
                                                    <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Department is provided with well-furnished and equipped laboratories with a sophisticated analytical instruments to carry out activities at both UG and PG level.</li>
                                                    <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />The teaching staffs in the department are highly qualified, more efficient, committed and competent.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <HodDisplay department="Pharmacy Practice" />
                                    <div className="section-title with-desc text-left clearfix">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">Our Teaching Faculty</h4>
                                        </div>
                                    </div>
                                    <div className="container">
                                        <div className="row paddingtop-1">
                                            {
                                                this.state.Datas.length === 0 ? <Loading /> :
                                                    this.state.Datas === "NO_999" ? <NoData /> :
                                                        this.state.Datas.map((data, i) => (
                                                            <div className="col-lg-6" key={i}>
                                                                <div className="row">
                                                                    <div className="col-lg-4">
                                                                        <div className="gallery-box">
                                                                            <ModalImage className="swaraimage1" small={data.Image} large={data.Image} alt="Image" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-8" style={{ margin: "auto" }}>
                                                                        <p style={{ color: "black", fontWeight: "bold" }}>{data.Name}</p>
                                                                        <p style={{ color: "black" }}>
                                                                            {data.Qualification}<br />
                                                                            {data.Designation}<br />
                                                                            <a href={data.Link} target="_blank">View Details</a>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <br />
                                                            </div>
                                                        ))
                                            }
                                        </div>
                                        <br />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <DepartmentSidebar />
                            </div>
                        </div>
                    </div>
                </div>
                <br />
            </div>
        )
    }
}