import React, { Component } from 'react'
import Header from '../layout/Header';
import Banner from '../Common/Banner';
import ProgrammeSidebar from './ProgrammeSidebar';

export default class ProgramsDoctorofPharmacy extends Component {

    render() {
        return (
            <div className="body_wrapper">
                <Header />
                <Banner title="Programme" image="banners/Programme.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="ttm-service-single-content-area">
                                    <div className="section-title with-desc text-left clearfix">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">Doctor of Pharmacy (Pharm D)</h4>
                                        </div>
                                    </div>
                                    <div className="section-title with-desc text-left clearfix">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h6 className="title">Eligibility</h6>
                                        </div>
                                    </div>
                                    <p className="justify">Candidates should have passed 10+2/PUC examination with Physics and Chemistry as compulsory subjects.</p>
                                    <b>Original Documents and 3 sets of photocopies of all documents required at the time of Admission for Pharm-D course</b>
                                    <div className="row paddingtop-1">
                                        <div className="col-lg-6">
                                            <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />P.U.C Markscard / 12th  Markscard</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />SSLC / 10th  Markscard</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Address proof</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Transfer certificate</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Aadhar Card</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Study and Conduct Certificate</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Caste and Income Certificate</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Color passport size photograph with name – 4 nos</li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-6">
                                            <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Parent’s PAN Card</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Caste and income certificate</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Migration certificate from the former University/Board</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Eligibility certificate from Rajiv Gandhi University of Health Sciences for CBSE/ICSE/Non-Karnataka/NRI/Foreigners</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Passport, Visa and Residential permit from Police Commissioner, Bangalore for NRI/Foreigners</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="section-title with-desc text-left clearfix">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">Doctor of Pharmacy - Post Baccalaureate (Pharm D - PB)</h4>
                                        </div>
                                    </div>
                                    <div className="section-title with-desc text-left clearfix">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h6 className="title">Eligibility</h6>
                                        </div>
                                    </div>
                                    <p className="justify">Candidates should have passed 10+2/PUC examination with Physics and Chemistry as compulsory subjects.</p>
                                    <b>Original Documents and 3 sets of photocopies of all documents required at the time of Admission for Pharm-D Post Baccalaureate course</b>
                                    <div className="row paddingtop-1">
                                        <div className="col-lg-6">
                                            <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />P.U.C Markscard / 12th  Markscard</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />SSLC / 10th  Markscard</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />B.Pharm (All markscards)</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />B.Pharm PDC/Convocation certificate</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Address proof</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Transfer certificate</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Aadhar Card</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Study and Conduct Certificate</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Color passport size photograph with name – 4 nos</li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-6">
                                            <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Parent’s PAN Card</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Caste and income certificate</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Migration certificate from the former University/Board</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Eligibility certificate from Rajiv Gandhi University of Health Sciences for CBSE/ICSE/Non-Karnataka/NRI/Foreigners</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Passport, Visa and Residential permit from Police Commissioner, Bangalore for NRI/Foreigners</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="section-title with-desc text-left clearfix paddingtop-2">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h6 className="title">Career opportunity in D.Pharma</h6>
                                        </div>
                                    </div>
                                    <h6 className="title">Private Jobs</h6>
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Clinical Pharmacist</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Hospital Pharmacy Director</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Hospital Staff Pharmacist</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Medical Writer</li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-4">
                                            <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Pharmaceutical Advisor</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Medical Safety Physician</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Supply Logistics leader</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Drug Safety Associate</li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-4">
                                            <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Pharmacovigilance</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Lecturer/Assistant Professor</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <h6 className="title paddingtop-2">Government Jobs</h6>
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Analytical Chemist- Pathological Scientist</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Food and Drug Inspector -Research Officer</li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-4">
                                            <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Hospital Drug Coordinator- Pharmacist</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Drug Therapist- Health Inspector</li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-4">
                                            <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Chemical/Drug Technician -Drug Therapist</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <ProgrammeSidebar />
                            </div>
                        </div>
                    </div>
                </div>
                <br />
            </div>
        )
    }
}
