import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

export default class FacilitiesSidebar extends Component {
    render() {
        return (
            <div>
                <div className="widget widget-nav-menu">
                    <ul className="widget-menu">
                        <li><NavLink to={process.env.PUBLIC_URL + '/about-library'}>Library</NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + '/about-scholarship'}>Scholarship</NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + '/about-laboratories'}>Laboratories</NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + '/about-hostel'}>Hostel</NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + '/about-transportation'}>Transportation</NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + '/about-nss'}>NSS</NavLink></li>
                    </ul>
                </div>
            </div>
        )
    }
}

//<li><NavLink to={process.env.PUBLIC_URL + '/about-placements'}>Placements</NavLink></li>