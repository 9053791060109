import React, { Component } from "react";
import {
  MDBNavbar,
  MDBNavbarNav,
  MDBNavItem,
  MDBCollapse,
  MDBDropdownItem,
  MDBHamburgerToggler,
} from "mdbreact";
import { BrowserRouter as Router } from "react-router-dom";

class Mobilemenu extends Component {
  state = {
    collapse1: false,
    collapseID: "",
  };

  toggleCollapse = (collapseID) => () => {
    this.setState((prevState) => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : "",
    }));
  };

  toggleSingleCollapse = (collapseId) => {
    this.setState({
      ...this.state,
      [collapseId]: !this.state[collapseId],
    });
  };

  render() {
    return (
      <Router>
        <MDBNavbar>
          <MDBHamburgerToggler
            id="hamburger1"
            onClick={() => this.toggleSingleCollapse("collapse1")}
          />
          <MDBCollapse isOpen={this.state.collapse1} navbar>
            <MDBNavbarNav left>
              <MDBNavItem>
                <MDBDropdownItem href={process.env.PUBLIC_URL + "/"}>
                  Home
                </MDBDropdownItem>
              </MDBNavItem>
              <MDBNavItem>
                <MDBDropdownItem
                  href={process.env.PUBLIC_URL + "/About-Management"}
                >
                  About Management
                </MDBDropdownItem>
              </MDBNavItem>
              <MDBNavItem>
                <MDBDropdownItem
                  href={process.env.PUBLIC_URL + "/About-College"}
                >
                  About College
                </MDBDropdownItem>
              </MDBNavItem>
              <MDBNavItem>
                <MDBDropdownItem
                  href={process.env.PUBLIC_URL + "/About-Chairman"}
                >
                  About Chairman
                </MDBDropdownItem>
              </MDBNavItem>
              <MDBNavItem>
                <MDBDropdownItem
                  href={process.env.PUBLIC_URL + "/About-Principal"}
                >
                  About Principal
                </MDBDropdownItem>
              </MDBNavItem>
              <MDBNavItem>
                <MDBDropdownItem href={process.env.PUBLIC_URL + "/About-Rules"}>
                  Rules &amp; Regulations
                </MDBDropdownItem>
              </MDBNavItem>
              <MDBNavItem>
                <MDBDropdownItem
                  href={process.env.PUBLIC_URL + "/About-Library"}
                >
                  Facilities
                </MDBDropdownItem>
              </MDBNavItem>
              <MDBNavItem>
                <MDBDropdownItem
                  href={process.env.PUBLIC_URL + "/Programs-D-Pharm"}
                >
                  Programs
                </MDBDropdownItem>
              </MDBNavItem>
              <MDBNavItem>
                <MDBDropdownItem
                  href={process.env.PUBLIC_URL + "/Departments-Pharmaceutics"}
                >
                  Departments
                </MDBDropdownItem>
              </MDBNavItem>
              <MDBNavItem>
                <MDBDropdownItem
                  href={process.env.PUBLIC_URL + "/Academics-Administrative"}
                >
                  Academics
                </MDBDropdownItem>
              </MDBNavItem>
              <MDBNavItem>
                <MDBDropdownItem
                  href={process.env.PUBLIC_URL + "/Statutory-Student-Grievance"}
                >
                  Statutory Cell
                </MDBDropdownItem>
              </MDBNavItem>
              <MDBNavItem>
                <MDBDropdownItem href={process.env.PUBLIC_URL + "/Contact"}>
                  Contact
                </MDBDropdownItem>
              </MDBNavItem>
              <MDBNavItem>
                <MDBDropdownItem href={process.env.PUBLIC_URL + "/Gallery"}>
                  Gallery
                </MDBDropdownItem>
              </MDBNavItem>
            </MDBNavbarNav>
          </MDBCollapse>
        </MDBNavbar>
      </Router>
    );
  }
}

export default Mobilemenu;
