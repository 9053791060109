import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Slider from "react-slick";
import { Styles } from "./styles/StudentAchivers.js";
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../../Config/FbConfig';

export default class StudentAchivers extends Component {

    state = {
        Datas: [],
        Loading: true
    }

    //Load Data Here
    async componentDidMount() {
        const docRef = doc(firestore, "Home", "StudentAchievers");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            var Data = []
            Object.entries(docSnap.data()).forEach(([key, value]) => {
                Data.push(Object.assign({ Id: key }, value))
            });
            this.setState({
                Datas: Data,
                Loading: false
            })
        } else {
            this.setState({
                Datas: "NO_999",
                Loading: false
            })
        }
    }

    render() {

        var slick_slider = {
            dots: true,
            arrow: true,
            autoplay: true,
            autoplaySpeed: 2500,
            speed: 2000,
            slidesToShow: 4,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1199,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                    },
                },
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                    },
                },
                {
                    breakpoint: 680,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    },
                },
                {
                    breakpoint: 575,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    },
                },
            ],
        };

        return (
            <Styles>
                <section className="team-member-area">
                    <Container>
                        <Row>
                            <Col md="5">
                                <div className="section-title style3 mb-0 clearfix">
                                    <div className="title-header mb-0">
                                        <h2 className="title ttm-textcolor-white"> <span className="ttm-textcolor-white" style={{ color: "#0f6464" }}>Student Achievers</span></h2>
                                    </div>
                                </div>
                            </Col>
                            <Col md="7" style={{ marginTop: "1rem" }}><hr /></Col>
                            <Col md="12" className="team-slider" style={{ paddingTop: "4rem", padding:"2rem"}}>
                                {this.state.Loading === true ? "Loading.." :
                                    <Slider className="row" {...slick_slider}>
                                        {
                                            this.state.Datas.map((data, i) => (
                                                <div className="team-item" key={i}>
                                                    <img src={data.Image} className="img-fluid" />
                                                    <div className="img-content text-center" style={{ maxHeight: "9rem", minHeight: "9rem" }}>
                                                        <p>{data.Description}</p>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </Slider>
                                }
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Styles>
        )
    }
}