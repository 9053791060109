import React from 'react';
import { createBrowserHistory } from 'history';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Layout
import { Footer } from './components/layout/Footer';

// pages
import Home from './components/Home';

import AboutManagement from "./components/about/AboutManagement";
import AboutCollege from "./components/about/AboutCollege";
import AboutChairman from "./components/about/AboutChairman";
import AboutPrincipal from "./components/about/AboutPrincipal";
import AboutRules from './components/about/AboutRules';
import AboutLibrary from './components/about/AboutLibrary';
import AboutPlacements from './components/about/AboutPlacements';
import AboutScholarship from './components/about/AboutScholarship';
import AboutLaboratories from './components/about/AboutLaboratories';
import AboutHostel from './components/about/AboutHostel';
import AboutTransportation from './components/about/AboutTransportation';
import AboutNSS from './components/about/AboutNSS';

import ProgramsDPharm from "./components/programme/ProgramsDPharm";
import ProgramsBPharm from "./components/programme/ProgramsBPharm";
import ProgramsDoctorofPharmacy from "./components/programme/ProgramsDoctorofPharmacy";
import ProgramsMPharm from "./components/programme/ProgramsMPharm";
import ProgramsPHD from "./components/programme/ProgramsPHD";

import Pharmaceutics from './components/departments/Pharmaceutics';
import Pharmacology from './components/departments/Pharmacology';
import PharmaceuticalChemistry from './components/departments/PharmaceuticalChemistry';
import Pharmacognosy from './components/departments/Pharmacognosy';
import PharmacyPractice from './components/departments/PharmacyPractice';

import Teaching from "./components/academics/Teaching";
import NonTeaching from "./components/academics/NonTeaching";
import SpecificFaculty from "./components/academics/SpecificFaculty";
import Research from './components/academics/Research';

import StatutoryStudentGrievance from './components/StatutoryCell/StatutoryStudentGrievance';
import StatutoryAntiDiscrimination from './components/StatutoryCell/StatutoryAntiDiscrimination';
import StatutoryAntiRagging from './components/StatutoryCell/StatutoryAntiRagging';
import StatutoryWomens from './components/StatutoryCell/StatutoryWomens';
import StatutoryGender from './components/StatutoryCell/StatutoryGender';
import StatutoryStaffGrievance from './components/StatutoryCell/StatutoryStaffGrievance';
import StatutoryPlacement from './components/StatutoryCell/StatutoryPlacement';
import StatutorySC from './components/StatutoryCell/StatutorySC';

import Alumni from './components/Alumni';
import Gallery from "./components/Gallery";
import Contact from './components/Contact';

import StudentGrivence from "./components/StudentGrivence";

import sent from './components/sent';
import ScrollToTop from './components/layout/Gotop';

const appHistory = {basename: process.env.PUBLIC_URL};
const history = createBrowserHistory(appHistory);
function App() {

  return (
    <div className="page">
      <Router basename={process.env.PUBLIC_URL} history={history}>
        <div id="preloader"><div id="status">&nbsp;</div></div>
        <ScrollToTop />
        <Route exact path='/' component={Home} />

        <Route path="/About-Management" component={AboutManagement} />
        <Route path="/About-College" component={AboutCollege} />
        <Route path="/About-Chairman" component={AboutChairman} />
        <Route path="/About-Principal" component={AboutPrincipal} />
        <Route path="/About-Rules" component={AboutRules} />
        <Route path="/About-Library" component={AboutLibrary} />
        <Route path="/About-Placements" component={AboutPlacements} />
        <Route path="/About-Scholarship" component={AboutScholarship} />
        <Route path="/About-Laboratories" component={AboutLaboratories} />
        <Route path="/About-Hostel" component={AboutHostel} />
        <Route path="/About-Transportation" component={AboutTransportation} />
        <Route path="/About-NSS" component={AboutNSS} />
        
        <Route path="/Programs-D-Pharm" component={ProgramsDPharm} />
        <Route path="/Programs-B-Pharm" component={ProgramsBPharm} />
        <Route path="/Programs-Doctor-Pharm" component={ProgramsDoctorofPharmacy} />
        <Route path="/Programs-M-Pharm" component={ProgramsMPharm} />
        <Route path="/Programs-PHD" component={ProgramsPHD} />

        <Route path="/Departments-Pharmaceutics" component={Pharmaceutics} />
        <Route path="/Departments-Pharmacology" component={Pharmacology} />
        <Route path="/Departments-Pharmaceutical-Chemistry" component={PharmaceuticalChemistry} />
        <Route path="/Departments-Pharmacognosy" component={Pharmacognosy} />
        <Route path="/Departments-Pharmacy-Practice" component={PharmacyPractice} />

        <Route path="/Academics-Teaching" component={Teaching} />
        <Route path="/Academics-Administrative" component={NonTeaching} />
        <Route path="/Academics-Specific/:id" component={SpecificFaculty} />
        <Route path="/Academics-Research" component={Research} />

        <Route path="/Statutory-Student-Grievance" component={StatutoryStudentGrievance} />
        <Route path="/Statutory-Anti-Discrimination" component={StatutoryAntiDiscrimination} />
        <Route path="/Statutory-Anti-Ragging" component={StatutoryAntiRagging} />
        <Route path="/Statutory-Womens-Grievance" component={StatutoryWomens} />
        <Route path="/Statutory-Gender-Sensitization" component={StatutoryGender} />
        <Route path="/Statutory-Staff-Grievance" component={StatutoryStaffGrievance} />
        <Route path="/Statutory-Placement" component={StatutoryPlacement} />
        <Route path="/Statutory-SC" component={StatutorySC} />

        <Route path="/Alumni" component={Alumni} />
        <Route path="/Gallery" component={Gallery} />
        <Route path="/contact" component={Contact} />

        <Route path="/Student-Grivence" component={StudentGrivence} />

        <Route path="/Sent" component={sent} />
        <Footer />
      </Router>
    </div>
  );
}

export default App;
