import React, { Component } from 'react'
import Header from '../layout/Header';
import Banner from '../Common/Banner';
import FacilitiesSidebar from './FacilitiesSidebar';

export default class AboutNSS extends Component {

    render() {
        return (
            <div className="body_wrapper">
                <Header />
                <Banner title="Facilities" image="banners/About.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="ttm-service-single-content-area">
                                    <div className="section-title with-desc text-left clearfix">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">NSS</h4>
                                        </div>
                                    </div>
                                    <div className="ttm-service-description">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/aboutfacilities/facilities_10.jpg"} alt="Error" />
                                            </div>
                                            <div className="col-md-7" style={{ margin: "auto" }}>
                                                <p className="text-justify">The National Service Scheme (NSS) is a Central Sector Scheme of Government of India, Ministry of Youth Affairs & Sports. It provides opportunity to Graduate & Post Graduate at colleges and University level of India to take part in various government led community service activities & programmes. The sole aim of the NSS is to provide hands on experience to young students</p>
                                            </div>
                                            <div className="col-md-12 paddingtop-1">
                                                <p className="text-justify">in delivering community service. World Tuberculosis Day was held at our college on 24th March as a NSS activity.</p>
                                            </div>
                                            <div className="col-lg-4 paddingtop-1">
                                                <div className="position-relative res-991-mt-30">
                                                    <div className="ttm_single_image-wrapper text-sm-center">
                                                        <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/aboutfacilities/facilities_10_1.jpg"} alt="single-img-seven" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 paddingtop-1">
                                                <div className="position-relative res-991-mt-30">
                                                    <div className="ttm_single_image-wrapper text-sm-center">
                                                        <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/aboutfacilities/facilities_10_2.jpg"} alt="single-img-seven" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 paddingtop-1">
                                                <div className="position-relative res-991-mt-30">
                                                    <div className="ttm_single_image-wrapper text-sm-center">
                                                        <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/aboutfacilities/facilities_10_3.jpg"} alt="single-img-seven" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <FacilitiesSidebar />
                            </div>
                        </div>
                    </div>
                </div>
                <br />
            </div>
        )
    }
}
