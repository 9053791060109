import React, { Component } from 'react'
import Header from '../layout/Header';
import Banner from '../Common/Banner';
import DepartmentSidebar from './DepartmentSidebar';
import HodDisplay from './HodDisplay';
import NoData from "../Common/NoData";
import Loading from "../Common/Loading";
import ModalImage from "react-modal-image";
import { firestore } from '../../Config/FbConfig';
import { doc, getDoc } from 'firebase/firestore';


export default class Pharmacology extends Component {

    state = { Datas: [] }

    async componentDidMount() {
        //For Teaching
        const docRef = doc(firestore, "FacultiesandStaff", "Teaching");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            var Data = []
            Object.entries(docSnap.data()).forEach(([key, value]) => {
                if (value.Department === "Pharmacology") {
                    Data.push(Object.assign(
                        {
                            Id: key,
                            Priority: value.Priority != undefined ? value.Priority : "999",
                            Image: value.Image,
                            Name: value.Name,
                            Description: value.Description,
                            Designation: value.Designation,
                            Qualification: value.Qualification,
                            Link: value.Link
                        }))
                }
            });

            Data.length === 0 ? this.setState({ Datas: "NO_999" }) : this.setState({ Datas: Data.sort(function (a, b) { return a.Priority - b.Priority; }) })

        }

        else { this.setState({ Datas: "NO_999" }) }
    }

    render() {
        return (
            <div className="body_wrapper">
                <Header />
                <Banner title="Pharmacology" image="departments/Pharmacology.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="ttm-service-single-content-area">
                                    <div className="section-title with-desc text-left clearfix">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">About Department</h4>
                                        </div>
                                    </div>
                                    <div className="ttm-service-description">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/departments/department_2.jpg"} alt="Error" />
                                            </div>
                                            <div className="col-md-7" style={{ margin: "auto" }}>
                                                <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                                                    <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Started PG pharmacology in 2001.</li>
                                                    <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Recognised PhD centre.</li>
                                                    <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Well equipped labs for research.</li>
                                                    <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />CPCSEA approved animal house.</li>
                                                    <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Organised a number of Guest Lecturer from eminent persons from the field for the benefit of students and faculty.</li>
                                                </ul>
                                            </div>
                                            <div className="col-md-6 paddingtop-1">
                                                <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                                                    <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />2 candidates completed PhD, 2 candidates are in Pipeline.</li>
                                                    <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Emphasis as Preclinical Drug Development.</li>
                                                    <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />More then 50 Research Publications.</li>
                                                    <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Best oral and Paper answered in conferences.</li>
                                                    <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />So far 84 candidates completed their M pharm from Pharmacognosy department.</li>
                                                </ul>
                                            </div>
                                            <div className="col-md-6 paddingtop-1">
                                                <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                                                    <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />RGUHS funded research.</li>
                                                    <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Govertment Placement Record.</li>
                                                    <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Organised and conducted RGUHS funded workshop.</li>
                                                    <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Active Participation in National and International conferences and Workshops.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <HodDisplay department="Pharmacology" />
                                    <div className="section-title with-desc text-left clearfix">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">Our Teaching Faculty</h4>
                                        </div>
                                    </div>
                                    <div className="container">
                                        <div className="row paddingtop-1">
                                            {
                                                this.state.Datas.length === 0 ? <Loading /> :
                                                    this.state.Datas === "NO_999" ? <NoData /> :
                                                        this.state.Datas.map((data, i) => (
                                                            <div className="col-lg-6" key={i}>
                                                                <div className="row">
                                                                    <div className="col-lg-4">
                                                                        <div className="gallery-box">
                                                                            <ModalImage className="swaraimage1" small={data.Image} large={data.Image} alt="Image" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-8" style={{ margin: "auto" }}>
                                                                        <p style={{ color: "black", fontWeight: "bold" }}>{data.Name}</p>
                                                                        <p style={{ color: "black" }}>
                                                                            {data.Qualification}<br />
                                                                            {data.Designation}<br />
                                                                            <a href={data.Link} target="_blank">View Details</a>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <br />
                                                            </div>
                                                        ))
                                            }
                                        </div>
                                        <br />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <DepartmentSidebar />
                            </div>
                        </div>
                    </div>
                </div>
                <br />
            </div>
        )
    }
}