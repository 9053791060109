import React, { Component } from 'react'
import Header from '../layout/Header';
import Banner from '../Common/Banner';
import FacilitiesSidebar from './FacilitiesSidebar';

export default class AboutLibrary extends Component {

    render() {
        return (
            <div className="body_wrapper">
                <Header />
                <Banner title="Facilities" image="banners/About.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="ttm-service-single-content-area">
                                    <div className="section-title with-desc text-left clearfix">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">Library</h4>
                                        </div>
                                    </div>
                                    <div className="ttm-service-description">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/aboutfacilities/facilities_1.jpg"} alt="Error" />
                                            </div>
                                            <div className="col-md-7" style={{ margin: "auto" }}>
                                                <p className="text-justify">Students and teaching faculty have access to an exclusive library within the institution. It is on the fourth floor of the VIPS college building, in a clean and quiet environment. The library is equipped with several wooden reading tables, comfortable chairs and fans, and also a good lighting system. The library staff ensures complete silence by issuing directions such as "no cellphone usage" and "keep silence." It contains a reading area with nearly sufficient space for all students and staffs. It is equipped with variety of Pharmacy and allied health related subject books and Journals. </p>
                                            </div>
                                            <div className="col-md-12 paddingtop-1">
                                                <p className="text-justify">Our Library has more than 10,000 books and also subscription to various national and International Journals are available both printed and electronic. VIPS library subscribes E-resource like Helinet, Pro-Quest and Elsevier. We are using Libsoft library automation software for easy and smooth functionality of library activities and to give best service to VIPS Students and Staff. Our vision, is to ‘’Build the learning resource centre as a centre for excellence in accessing the right information at a right time to the right user’’. Students can borrow books by using their library card.</p>
                                            </div>
                                            <div className="col-md-12 paddingtop-1"><h6>List of some Journals </h6></div>
                                            <div className="col-lg-6 paddingtop-1">
                                                <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                                                    <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Asian Journal of Pharmaceutical Analysis.</li>
                                                    <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />International Journal of Pharmaceutical Research and Analysis.</li>
                                                    <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />International Journal of Pharmacy.</li>
                                                    <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />International Journal of Pharmacy and Therapeutics.</li>
                                                    <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />International Journal of Bio-pharmaceutics.</li>
                                                </ul>
                                            </div>
                                            <div className="col-lg-6 paddingtop-1">
                                                <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                                                    <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />International Journal of Advanced Pharmaceutics</li>
                                                    <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />International Journal of Drug development and Technology.</li>
                                                    <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />International Journal of Drug Targets. </li>
                                                    <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />International Journal of Pharmacy Practice and Drug Research.</li>
                                                    <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Indian Journal of Pharmacology.</li>
                                                </ul>
                                            </div>
                                            <div className="col-lg-4 paddingtop-1">
                                                <div className="position-relative res-991-mt-30">
                                                    <div className="ttm_single_image-wrapper text-sm-center">
                                                        <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/aboutfacilities/facilities_2.jpg"} alt="single-img-seven" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 paddingtop-1">
                                                <div className="position-relative res-991-mt-30">
                                                    <div className="ttm_single_image-wrapper text-sm-center">
                                                        <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/aboutfacilities/facilities_3.jpg"} alt="single-img-seven" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 paddingtop-1">
                                                <div className="position-relative res-991-mt-30">
                                                    <div className="ttm_single_image-wrapper text-sm-center">
                                                        <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/aboutfacilities/facilities_4.jpg"} alt="single-img-seven" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <FacilitiesSidebar />
                            </div>
                        </div>
                    </div>
                </div>
                <br />
            </div>
        )
    }
}