import React, { Component } from 'react'
import Header from '../layout/Header';
import Banner from '../Common/Banner';

export default class AboutCollege extends Component {
    render() {
        return (
            <div>
                <Header />
                <Banner title="About College" image="banners/About.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl paddingtop-2">
                        <div className="ttm-service-single-content-area">
                            <div className="ttm-service-description">
                                <div className="row">
                                    <div className="col-md-5">
                                        <div className="ttm_single_image-wrapper">
                                            <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/aboutcollege/college-1.jpg"} alt="single-img-eighteen" />
                                        </div>
                                    </div>
                                    <div className="col-md-7 justify" style={{ margin: "auto" }}>
                                        <p className="about-para">Visveswarapura Institute of Pharmaceutical Sciences, also known as the first private pharmacy college in Bangalore, was founded in 1980 by Rajya Vokkaligara Sangha. Our college is affiliated to Rajiv Gandhi University of Health Sciences(RGUHS), Karnataka and is recognized by the Pharmacy Council of India (PCI).</p>
                                        <p className="about-para">Visveswarapura Institute of Pharmaceutical Sciences is one of Bangalore's best pharmacy schools, featuring state-of-the-art laboratory facilities and a well-stocked digital library. Students are taught by the best, highly qualified, and most experienced teaching faculty available. Our college also has separate men's and women's hostels, each with its own set of amenities for boarding and other sporting areas.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br /><br /><br /><br /><br />
                <section className="clearfix paddingtop-2">
                    <div className="container-xl" style={{ paddingBlockEnd: "40px" }}>
                        <div className="row no-gutters mt_100 res-991-mt-0">
                            <div className="col-md-6">
                                <div className="ttm-bgcolor-darkgrey ttm-textcolor-white z-11 position-relative h-100">
                                    <div className="layer-content">
                                        <div className="spacing-2">
                                            <div className="section-title ttm with-desc clearfix"><div className="title-header"><h2 className="title">Vision</h2></div></div>
                                            <div className="ttm-progress-bar">
                                                <div className="progress-bar-title float-left">"To emerge as the center of excellence focusing on integrating quality education, research activities and innovation, thereby matching the needs of Pharma industry and to create entrepreneurs and leaders with moral values"</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="col-bg-img-two ttm-col-bgimage-yes ttm-bg ttm-col-bgcolor-yes ttm-bgcolor-skincolor ttm-textcolor-white">
                                    <div className="section-title ttm with-desc clearfix">
                                        <div className="spacing-2">
                                            <div className="title-header"><h2 className="title">Mission</h2></div>
                                            <div>
                                                <ul>
                                                    <li>* To provide applied and scientific learning</li>
                                                    <li>* To advance collaborative research and linkages</li>
                                                    <li>* To enable innovative and well-designed practical techniques</li>
                                                    <li>* To foster integrity and human values</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
