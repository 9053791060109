import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

export class ProgrammeSidebar extends Component {
    render() {
        return (
            <div>
                <div className="widget widget-nav-menu">
                    <ul className="widget-menu">                   
                        <li><NavLink to={process.env.PUBLIC_URL + '/Programs-D-Pharm'}>D. Pharm Course</NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + '/Programs-B-Pharm'}>B.Pharm Course</NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + '/Programs-Doctor-Pharm'}>Doctor of Pharmacy (Pharm D)</NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + '/Programs-M-Pharm'}>M.Pharm Course</NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + '/Programs-PHD'}>Doctor of Philosophy (PhD)</NavLink></li>
                    </ul>
                </div>
            </div>
        );
    }
}

export default ProgrammeSidebar;
