import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

export default class StatutorySidebar extends Component {
    render() {
        return (
            <div className="widget widget-nav-menu">
                <ul className="widget-menu">
                    <li><NavLink className="nav-link" to='/Statutory-Student-Grievance'>Student Grievance Redressal Cell</NavLink></li>
                    <li><NavLink className="nav-link" to='/Statutory-Anti-Discrimination'>Anti Discrimination Cell</NavLink></li>
                    <li><NavLink className="nav-link" to='/Statutory-Anti-Ragging'>Anti Ragging Committee</NavLink></li>
                    <li><NavLink className="nav-link" to='/Statutory-Womens-Grievance'>Womens Grievance Cell</NavLink></li>
                    <li><NavLink className="nav-link" to='/Statutory-Gender-Sensitization'>Gender Sensitization Cell</NavLink></li>
                    <li><NavLink className="nav-link" to='/Statutory-Staff-Grievance'>Staff Grievance Cell</NavLink></li>
                    <li><NavLink className="nav-link" to='/Statutory-Placement'>Placement and Career Cell</NavLink></li>
                    <li><NavLink className="nav-link" to='/Statutory-SC'>SC / ST Cell</NavLink></li>
                </ul>
            </div>
        )
    }
}