import React, { Component } from 'react'
import Header from '../layout/Header';
import Banner from '../Common/Banner';
import ProgrammeSidebar from './ProgrammeSidebar';

export default class ProgramsPHD extends Component {

    render() {
        return (
            <div className="body_wrapper">
                <Header />
                <Banner title="Programme" image="banners/Programme.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="ttm-service-single-content-area">
                                    <div className="section-title with-desc text-left clearfix">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">Doctor of Philosophy (PhD)</h4>
                                        </div>
                                    </div>
                                    <b>Commencement of session : June and November</b>
                                    <p className='justify paddingtop-1'>Visveswarapura Institute of Pharmaceutical Sciences is the ideal place for people who want to pursue their studies and even get a doctorate in most of the chosen fields. When it comes to PH. D we offer it for two main sections: Doctorate of Philosophy (PH D) Pharmacology, Doctorate of Philosophy (PH D) Pharmacy Practice. Our skilled faculty and our well stocked library along with the overall learning environment of the college will ensure that you will be able to finish your doctorate in the chosen field well within the accepted time frame, not to mention the superior quality of your work too.</p>
                                    <div className="section-title with-desc text-left clearfix">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h6 className="title">Course Duration</h6>
                                        </div>
                                    </div>
                                    Full Time: <b>3 years</b>&nbsp;|&nbsp;Part Time: <b>5 years</b>
                                    <div className="section-title with-desc text-left clearfix paddingtop-2">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h6 className="title">Eligibility</h6>
                                        </div>
                                    </div>
                                    <p className="justify">A candidate who has passed M.Pharm degree examination of R GUHS with 1st class or M.Pharm examination of any other recognized Indian University with 1st class established by law in India or any other degree courses in pharmacy recognized as equivalent by RGUHS University or Pharmacy Council of India(PCI) and/or All India Council of Technical Education (A I.C.T.E,) for this purpose and who has secured not less than 50% of the maximum marks (aggregate of four years) prescribed for the qualifying examination shall be eligible for admission to the PhD course.</p>
                                    <div className="section-title with-desc text-left clearfix paddingtop-1">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h6 className="title">Specializations</h6>
                                        </div>
                                    </div>
                                    1. Pharmacy Practice&nbsp;&nbsp;&nbsp;2. Pharmacology
                                    <div className="section-title with-desc text-left clearfix paddingtop-2">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h6 className="title">Career opportunity in PH D </h6>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Researchery</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Project Manager</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Pharmaceutical Engineer</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Pharmacy coordinator</li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-6">
                                            <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Clinical pharmacist</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Pharmacy manager</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Education Developer</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Professor</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <ProgrammeSidebar />
                            </div>
                        </div>
                    </div>
                </div>
                <br />
            </div>
        )
    }
}


