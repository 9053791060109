import React, { Component } from 'react'
import Header from '../layout/Header';
import Banner from '../Common/Banner';
import FacilitiesSidebar from './FacilitiesSidebar';

export default class AboutPlacements extends Component {

    render() {
        return (
            <div className="body_wrapper">
                <Header />
                <Banner title="Facilities" image="banners/About.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="ttm-service-single-content-area">
                                    <div className="section-title with-desc text-left clearfix">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">Placements</h4>
                                        </div>
                                    </div>
                                    <div className="ttm-service-description">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/aboutfacilities/facilities_5.jpg"} alt="Error" />
                                            </div>
                                            <div className="col-md-7" style={{ margin: "auto" }}>
                                                <p className="text-justify">To keep our students professionally updated to the unfolding dynamic environment, our center offers apex quality training services, many career guidance and development programmes on personality development, communication skills. The Center provides employment opportunities for the students in acclaimed world class organizations. Every year more than 10 top notch companies are our recruiters. Our resourceful alumnae have set commendable standards in the corporate world through their admirable contributions. The consistent placement record illustrates our commitment and devotion towards creating employment opportunities</p>
                                            </div>
                                            <div className="col-md-12 paddingtop-1">
                                                <p className="text-justify">to our students. We have excellent infrastructure facilities in terms of full-fledged seminar hall, training hall, conference and board rooms, interview cabins, full-fledged computer labs for conducting recruitment process. Our Recruiters: Dr. Reddy’s Biocon Himalaya Drug Company Cipla Astrazeneca Microlabs Apotex Zydus IQVIA IMS Accenture Cadilla NovoNordisk Recon Dabur Ranbaxy To name a few and many more.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <FacilitiesSidebar />
                            </div>
                        </div>
                    </div>
                </div>
                <br />
            </div>
        )
    }
}
