import React, { Component } from 'react'
import Header from '../layout/Header';
import Banner from '../Common/Banner';
import NoData from "../../components/Common/NoData";
import Loading from "../../components/Common/Loading";
import { firestore } from '../../Config/FbConfig';
import { doc, getDoc } from 'firebase/firestore';
import StatutorySidebar from './StatutorySidebar';

export default class StatutoryGender extends Component {

    state = { Datas: [] }

    async componentDidMount() {
        //For Teaching
        const docRef = doc(firestore, "StatutoryCell", "Gender");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            var Data = []
            Object.entries(docSnap.data()).forEach(([key, value]) => {
                Data.push(Object.assign(
                    {
                        Id: key,
                        Priority: value.Priority != undefined ? value.Priority : "999",
                        Name: value.Name,
                        Description: value.Description,
                        Position: value.Position,
                        Phone: value.Phone,
                        Email: value.Email,
                    }))
            });

            Data.length === 0 ? this.setState({ Datas: "NO_999" }) : this.setState({ Datas: Data.sort(function (a, b) { return a.Priority - b.Priority; }) })

        }

        else { this.setState({ Datas: "NO_999" }) }
    }

    render() {
        return (
            <div className="body_wrapper">

                <Header />
                <Banner title="Statutory Cell" image="banners/Commitees.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="ttm-service-single-content-area">
                                    <div className="section-title with-desc text-left clearfix paddingtop-2">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">Gender Sensitization Cell</h4>
                                        </div>
                                    </div>
                                    <p className="justify">The Gender Sensitization Cell of <b>VISVESWARAPURA INSTITUTE OF PHARMACEUTICAL SCIENCES</b> is reconstituted as per SAKSHAM guidelines with the following members as specified below.</p>
                                    <div className="ttm-service-description">
                                        <div className="row paddingtop-1">
                                            <table class="table">
                                                <tbody>
                                                    <tr>
                                                        <th>Sl</th>
                                                        <th>Name</th>
                                                        <th>Position</th>
                                                        <th>Mobile & Email</th>
                                                    </tr>
                                                    {
                                                        this.state.Datas.length === 0 ? <Loading /> :
                                                            this.state.Datas === "NO_999" ? <NoData /> :
                                                                this.state.Datas.map((data, i) => (
                                                                    <tr key={i}>
                                                                        <th scope="row">{i + 1}</th>
                                                                        <td>{data.Name}<br />{data.Description}</td>
                                                                        <td>{data.Position}</td>
                                                                        <td>{data.Phone}<br />{data.Email}</td>
                                                                    </tr>
                                                                ))
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <StatutorySidebar />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}