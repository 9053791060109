import React, { Component } from 'react'
import Header from '../layout/Header';
import Banner from '../Common/Banner';
import FacilitiesSidebar from './FacilitiesSidebar';

export default class AboutLaboratories extends Component {

    render() {
        return (
            <div className="body_wrapper">
                <Header />
                <Banner title="Facilities" image="banners/About.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="ttm-service-single-content-area">
                                    <div className="section-title with-desc text-left clearfix">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">Laboratories</h4>
                                        </div>
                                    </div>
                                    <div className="ttm-service-description">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/aboutfacilities/facilities_7.jpg"} alt="Error" />
                                            </div>
                                            <div className="col-md-7" style={{ margin: "auto" }}>
                                                <p className="text-justify">Our labs are equipped with latest equipments to carry out experiments and research.</p>
                                            </div>
                                            <div className="col-lg-4 paddingtop-1">
                                                <div className="position-relative res-991-mt-30">
                                                    <div className="ttm_single_image-wrapper text-sm-center">
                                                        <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/aboutfacilities/facilities_7_a.jpg"} alt="single-img-seven" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 paddingtop-1">
                                                <div className="position-relative res-991-mt-30">
                                                    <div className="ttm_single_image-wrapper text-sm-center">
                                                        <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/aboutfacilities/facilities_7_b.jpg"} alt="single-img-seven" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 paddingtop-1">
                                                <div className="position-relative res-991-mt-30">
                                                    <div className="ttm_single_image-wrapper text-sm-center">
                                                        <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/aboutfacilities/facilities_7_c.jpg"} alt="single-img-seven" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <FacilitiesSidebar />
                            </div>
                        </div>
                    </div>
                </div>
                <br />
            </div>
        )
    }
}
