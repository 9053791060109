import React, { Component } from "react";
import Header from "../layout/Header";
import Banner from "../Common/Banner";

export default class AboutPrincipal extends Component {
  render() {
    return (
      <div className="body_wrapper">
        <Header />
        <Banner title="Principal Desk" image="banners/Principal.jpg" />

        {/* Content */}
        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl paddingtop-2">
            <div className="row">
              <div className="col-lg-4">
                <div className="ttm-featured-wrapper">
                  <div className="featured-thumbnail text-center">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/aboutprincipal/principal.png"
                      }
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-8 justify" style={{ margin: "auto" }}>
                <div className="ttm-team-member-content shadow-box res-991-mt-30">
                  <div className="ttm-team-member-content shadow-box res-991-mt-30">
                    <div className="ttm-team-member-single-list">
                      <h2 className="ttm-team-member-single-title">
                        Dr.Narmadha G.Y
                      </h2>
                      <span className="ttm-team-member-single-position">
                        M.Pharm , PhD
                      </span>
                      <a
                        className="ttm-btn ttm-btn-size-md ttm-bgcolor-darkgrey ttm-textcolor-white"
                        href={
                          process.env.PUBLIC_URL +
                          "/assets/images/aboutprincipal/Dr_Anil_Kumar_K_V.pdf"
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                        download
                      >
                        More Information Click Here
                      </a>
                      <hr />
                      <p>Dear Students,</p>
                      <p>
                        I’m happy to welcome you all to the Visveswarapura
                        Institute of Pharmaceutical Sciences website.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 paddingtop-1 justify">
                <p>
                  Visveswarapura Institute of Pharmaceutical Sciences, (VIPS),
                  the four decade old premier Pharmacy College committed to
                  Professional excellence under the management of Rajya
                  Vokkaligara Sangha.
                </p>
                <p>
                  The college has been rendering valuable service to Healthcare
                  Sciences globally. It is offering Diploma in Pharmacy
                  (D.Pharm), Bachelor of Pharmacy (B. Pharm), Master of Pharmacy
                  (M.Pharm), Doctor of Pharmacy (Pharm. D), and Doctoral
                  Programs (Ph.D.) Programs. The college is recognized by
                  Government of Karnataka, approved by Pharmacy Council of India
                  (PCI), New Delhi and affiliated to Rajiv Gandhi University of
                  Health Sciences (RGUHS), Karnataka is having state of art
                  facilities to cater the need of Pharmacy curriculum. It has an
                  excellent team of well qualified, experienced teaching staff
                  and supporting staff. Due to the combined and concerted
                  efforts our students, faculty and alumni across the globe, we
                  have earned reputation in imparting Pharmacy education since
                  inception of college. Our Institution provides the student
                  with a stress-free atmosphere for learning and developing
                  his/her intellectual capabilities. Being amongst top Pharmacy
                  colleges affiliated to RGUHS, India, we believe in
                  versatility. Beyond academics and research, the college also
                  emphasizes students on sports, cultural, co-curricular,
                  extra-curricular and social-service activities. Student
                  safety, discipline &amp; punctuality are a priority in our
                  campus. To experience the true joy of learning, we welcome the
                  young dynamic students to join Visveswarapura Institute of
                  Pharmaceutical Sciences to be a part in building a strong
                  knowledgeable nation.
                </p>
                <p>With Best Regards</p>
                <p>
                  <b style={{ fontWeight: "bold" }}>Dr.Narmadha G.Y</b>
                  <br />
                  Principal
                </p>
              </div>
            </div>
          </div>
        </div>
        <br />
      </div>
    );
  }
}
