import React, { Component } from "react";
import Header from "../layout/Header";
import Banner from "../Common/Banner";

export default class AboutChairman extends Component {
  render() {
    return (
      <div className="body_wrapper">
        <Header />
        <Banner title="Chairman's Message" image="banners/About.jpg" />
        {/* Content */}~
        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl paddingtop-2">
            <div className="row">
              <div className="col-lg-3">
                <div className="ttm-featured-wrapper">
                  <div className="featured-thumbnail text-center">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        `/assets/images/aboutchairman/chairman.jpeg`
                      }
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-9 justify" style={{ margin: "auto" }}>
                <div className="ttm-team-member-content shadow-box res-991-mt-30">
                  <div className="ttm-team-member-content shadow-box res-991-mt-30">
                    <div className="ttm-team-member-single-list">
                      <a
                        href="https://www.shoonyeka.com/bit-principal-production/"
                        target="_blank"
                      >
                        <h2 className="ttm-team-member-single-title">
                          Dr. M.B.Manjegowda
                        </h2>
                      </a>
                      <span className="ttm-team-member-single-position">
                        Chairman, VIPS, Bengalore <br /> +91 94481 50422
                      </span>
                      <hr />
                      <p>N/A</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 paddingtop-2 justify">
                <p>N/A</p>
                <p>With Best Regards</p>
                <p>
                  <b style={{ fontWeight: "bold" }}>Dr. M.B.Manjegowda</b>
                  <br />
                  Chairman, VIPS, Bengalore
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
