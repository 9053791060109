import React, { Component } from "react";
import { MDBAnimation, MDBCarousel, MDBCarouselCaption, MDBCarouselInner, MDBCarouselItem, MDBView, MDBContainer, MDBRow } from "mdbreact";

export class CarouselPage extends Component {
    render() {
        return (
            <div>
                <MDBContainer fluid className="hide-on-mob">
                    <MDBRow>
                        <MDBCarousel activeItem={1} length={4} showControls={true} showIndicators={false} id="Home_banner">
                            <MDBCarouselInner>
                                <MDBCarouselItem itemId="1">
                                    <MDBView><img className="d-block w-100" src={process.env.PUBLIC_URL + "/assets/images/home/Lander01.jpg"} alt="First slide" /></MDBView>
                                    <MDBCarouselCaption>
                                        <div className="container-xl">
                                            <div className="carousel-caption-inner banner1">
                                                <MDBAnimation className="slideInUp" count={1} delay="2s">
                                                    <h2 className="ttm-textcolor-darkgrey flipInY">Pharmacy is not only learning about drugs, <strong className="ttm-textcolor-skincolor">but to train Proffesional </strong>to cure the lives.</h2>
                                                </MDBAnimation>
                                            </div>
                                        </div>
                                    </MDBCarouselCaption>
                                </MDBCarouselItem>
                                <MDBCarouselItem itemId="2">
                                    <MDBView><img className="d-block w-100" src={process.env.PUBLIC_URL + "/assets/images/home/Lander02.jpg"} alt="Second slide" /></MDBView>
                                    <MDBCarouselCaption>
                                        <div className="container-xl">
                                            <div className="carousel-caption-inner banner1">
                                                <MDBAnimation className="slideInUp" count={1} delay="3s">
                                                    <h2 className="ttm-textcolor-darkgrey flipInY">Strentheneing, Collaborative researchers and <strong className="ttm-textcolor-skincolor">aspiring to meet the </strong>global challenges.</h2>
                                                </MDBAnimation>
                                            </div>
                                        </div>
                                    </MDBCarouselCaption>
                                </MDBCarouselItem>
                                <MDBCarouselItem itemId="3">
                                    <MDBView><img className="d-block w-100" src={process.env.PUBLIC_URL + "/assets/images/home/Lander03.jpg"} alt="Third slide" /></MDBView>
                                    <MDBCarouselCaption>
                                        <div className="container-xl">
                                            <div className="carousel-caption-inner banner1">
                                                <MDBAnimation className="slideInUp" count={1} delay="4s">
                                                    <h2 className="ttm-textcolor-darkgrey flipInY">Skilled <strong className="ttm-textcolor-skincolor">human resource<br/>provides </strong>for pharmaceutical<br/>Industry.</h2>
                                                </MDBAnimation>
                                            </div>
                                        </div>
                                    </MDBCarouselCaption>
                                </MDBCarouselItem>
                                <MDBCarouselItem itemId="4">
                                    <MDBView><img className="d-block w-100" src={process.env.PUBLIC_URL + "/assets/images/home/Lander04.jpg"} alt="Forth slide" /></MDBView>
                                    <MDBCarouselCaption>
                                        <div className="container-xl">
                                            <div className="carousel-caption-inner banner1">
                                                <MDBAnimation className="slideInUp" count={1} delay="5s">
                                                    <h2 className="ttm-textcolor-darkgrey flipInY">The best and Most <strong className="ttm-textcolor-skincolor">effecient <br/>pharmacy </strong>is within <br/>your own system.</h2>
                                                </MDBAnimation>
                                            </div>
                                        </div>
                                    </MDBCarouselCaption>
                                </MDBCarouselItem>
                            </MDBCarouselInner>
                        </MDBCarousel>
                    </MDBRow>
                </MDBContainer>
            </div>
        );
    }
}

export default CarouselPage;


// <MDBContainer fluid className="show-on-mob">
// <MDBRow>
//     <MDBCarousel activeItem={1} length={3} showControls={true} showIndicators={false} id="Home_banner">
//         <MDBCarouselInner>
//             <MDBCarouselItem itemId="1">
//                 <MDBView><img className="d-block w-100" src={process.env.PUBLIC_URL + "/assets/images/home/slider01.jpg"} alt="First slide" /></MDBView>
//                 <MDBCarouselCaption>
//                     <div className="container-xl">
//                         <div className="carousel-caption-inner banner1">
//                             <MDBAnimation className="slideInUp" count={1} delay="0.25s" >
//                                 <h2 className="ttm-textcolor-darkgrey flipInY"><h3>Info 1 Needed</h3><strong className="ttm-textcolor-skincolor">Info 1 Needed</strong></h2>
//                             </MDBAnimation>
//                             <MDBAnimation className="slideInUp" count={1} delay="0.5s" >
//                                 <p className="text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod, Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
//                             </MDBAnimation>
//                             <MDBAnimation className="slideInUp" count={1} delay="1s" >
//                                 <p><Link className="ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor ttm-textcolor-white" to="/Admission-Form">Admission</Link></p>
//                             </MDBAnimation>
//                         </div>
//                     </div>
//                 </MDBCarouselCaption>
//             </MDBCarouselItem>
//             <MDBCarouselItem itemId="2">
//                 <MDBView><img className="d-block w-100" src={process.env.PUBLIC_URL + "/assets/images/home/slider02.jpg"} alt="Second slide" /></MDBView>
//                 <MDBCarouselCaption>
//                     <div className="container-xl">
//                         <div className="carousel-caption-inner banner2">
//                             <MDBAnimation className="slideInUp" count={1} delay="0.25s" >
//                                 <h2 className="ttm-textcolor-darkgrey flipInY"><h3>Info 2 Needed </h3><strong className="ttm-textcolor-skincolor">Info 2 Needed</strong></h2>
//                             </MDBAnimation>
//                             <MDBAnimation className="slideInUp" count={1} delay="0.5s" >
//                                 <p className="text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod, Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
//                             </MDBAnimation>
//                             <MDBAnimation className="slideInUp" count={1} delay="1s" >
//                                 <p><Link className="ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor ttm-textcolor-white" to="/Programme">Programme</Link></p>
//                             </MDBAnimation>
//                         </div>
//                     </div>
//                 </MDBCarouselCaption>
//             </MDBCarouselItem>
//             <MDBCarouselItem itemId="3">
//                 <MDBView><img className="d-block w-100" src={process.env.PUBLIC_URL + "/assets/images/home/slider03.jpg"} alt="Third slide" /></MDBView>
//                 <MDBCarouselCaption>
//                     <div className="container-xl">
//                         <div className="carousel-caption-inner banner1">
//                             <MDBAnimation className="slideInUp" count={1} delay="0.25s" >
//                                 <h2 className="ttm-textcolor-darkgrey flipInY"><h3>Info 3 Needed </h3><strong className="ttm-textcolor-skincolor">Info 3 Needed</strong></h2>
//                             </MDBAnimation>
//                             <MDBAnimation className="slideInUp" count={1} delay="9.5s" >
//                                 <p className="text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod, Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
//                             </MDBAnimation>
//                             <MDBAnimation className="slideInUp" count={1} delay="1s" >
//                                 <p><Link className="ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor ttm-textcolor-white" to="/Life-Scope">Know More</Link></p>
//                             </MDBAnimation>
//                         </div>
//                     </div>
//                 </MDBCarouselCaption>
//             </MDBCarouselItem>
//         </MDBCarouselInner>
//     </MDBCarousel>
// </MDBRow>
// </MDBContainer>