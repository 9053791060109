import React, { Component } from 'react'
import Header from '../layout/Header';
import Banner from '../Common/Banner';
import ProgrammeSidebar from './ProgrammeSidebar';

export default class ProgramsMPharm extends Component {

    render() {
        return (
            <div className="body_wrapper">
                <Header />
                <Banner title="Programme" image="banners/Programme.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="ttm-service-single-content-area">
                                    <div className="section-title with-desc text-left clearfix">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">M. Pharm Course</h4>
                                        </div>
                                    </div>
                                    <div className="section-title with-desc text-left clearfix paddingtop-1">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h6 className="title">Specializations</h6>
                                        </div>
                                    </div>
                                    1. Pharmaceutics&nbsp;&nbsp;&nbsp;2. Pharmacy Practice&nbsp;&nbsp;&nbsp;3.  Pharmaceutical chemistry&nbsp;&nbsp;&nbsp;4. Pharmacology&nbsp;&nbsp;&nbsp;5. Pharmacognosy&nbsp;&nbsp;&nbsp;
                                    <div className="section-title with-desc text-left clearfix paddingtop-2">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h6 className="title">Eligibility</h6>
                                        </div>
                                    </div>
                                    <p className="justify">B.Pharm Graduate with 55% aggregate.</p>
                                    <b>Original Documents and 3 sets of photocopies of all documents required at the time of Admission for Pharm-D Post Baccalaureate course</b>
                                    <div className="row paddingtop-1">
                                        <div className="col-lg-6">
                                            <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />P.U.C Markscard / 12th  Markscard</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />SSLC / 10th  Markscard</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />B.Pharm (All markscards)</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />B.Pharm PDC/Convocation certificate</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Address proof</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Transfer certificate</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Aadhar Card</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Study and Conduct Certificate</li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-6">
                                            <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Color passport size photograph – 4 nos</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Parent’s PAN Card</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Migration certificate from the former University/Board</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Eligibility certificate from Rajiv Gandhi University of Health Sciences for CBSE/ICSE/Non-Karnataka/NRI/Foreigners</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Passport, Visa and Residential permit from Police Commissioner, Bangalore for NRI/Foreigners</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="section-title with-desc text-left clearfix paddingtop-2">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h6 className="title">Career opportunity in M.Pharma</h6>
                                        </div>
                                    </div>
                                    <h6 className="title paddingtop-1">M.Pharm (Pharmaceutics)</h6>
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Medical Transcriptionist</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Health Care Unit Manager</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Lab Technician</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Drug Inspector</li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-4">
                                            <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Research Associate</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Analytical Chemist</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Customs officer</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Medical Writing</li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-4">
                                            <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Regulatory affairs associate</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Clinical project manager</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Lecturer/Assistant Professor</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Primary school teacher</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <h6 className="title paddingtop-2">M.Pharm (Pharmacology)</h6>
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Academic Researcher</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Analytical Chemist</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Biomedical Scientist</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Clinical Research Associate</li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-4">
                                            <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Clinical Scientist, Biochemistry</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Clinical Scientist, Immunology</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Medicinal Chemist</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Pharmacologist</li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-4">
                                            <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Scientist (Life Sciences)</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Research Scientist (Medical)</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Lecturer/Assistant Professor</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Primary school teacher</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <h6 className="title paddingtop-2">M.Pharm (Pharmacy Practice)</h6>
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Clinical Research</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Hospitals</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Medical Colleges</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Sales</li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-4">
                                            <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Medical Writing</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Community Pharmacy</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Pharmacovigilance</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Quality Control</li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-4">
                                            <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Lecturer/Assistant Professor</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Primary school teacher</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <h6 className="title paddingtop-2">M.Pharm (Pharmaceutical chemistry)</h6>
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />R and D chemist</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Analytical Chemist</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Pharmacist</li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-4">
                                            <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Production in charge</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Project manager</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Market research analyst</li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-4">
                                            <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Lecturer/Assistant Professor</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Primary school teacher</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <h6 className="title paddingtop-2">M.Pharm (Pharmacognosy)</h6>
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Regulatory Manager</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Drug Inspector</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Health Care Unit Manager</li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-4">
                                            <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Analytical Chemist</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Medical Transcriptionist</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Associate research scientist</li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-4">
                                            <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Biomedical scientist</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Lecturer/Assistant Professor</li>
                                                <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Primary school teacher</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <ProgrammeSidebar />
                            </div>
                        </div>
                    </div>
                </div>
                <br />
            </div>
        )
    }
}

