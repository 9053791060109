import React, { Component } from 'react'
import Header from '../layout/Header';
import Banner from '../Common/Banner';

export default class Research extends Component {

    render() {
        return (
            <div className="body_wrapper">
                <Header />
                <Banner title="Research" image="banners/About.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl paddingtop-2">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-title with-desc text-left clearfix paddingtop-2">
                                    <div className="title-header" style={{ borderTopColor: "red" }}>
                                        <h4 className="title">Pharmacology</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                                    <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Molecular biology</li>
                                    <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Pharmacovigilance</li>
                                    <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />In-vivo and in-vitro biology</li>
                                </ul>
                            </div>
                            <div className="col-md-6">
                                <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                                    <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Pre-clinical trials</li>
                                    <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Genetic Toxicity</li>
                                    <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Pharmacokinetics and Pharmacodynamics</li>
                                </ul>
                            </div>
                            <div className="col-md-12">
                                <div className="section-title with-desc text-left clearfix paddingtop-2">
                                    <div className="title-header" style={{ borderTopColor: "red" }}>
                                        <h4 className="title">Pharmaceutical Chemistry</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                                    <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Medicinal chemistry</li>
                                    <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Computational chemistry</li>
                                    <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Drug docking</li>
                                </ul>
                            </div>
                            <div className="col-md-6">
                                <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                                    <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Clinical chemistry</li>
                                    <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Synthetic chemistry</li>
                                    <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Analytical chemistry</li>
                                </ul>
                            </div>
                            <div className="col-md-12">
                                <div className="section-title with-desc text-left clearfix paddingtop-2">
                                    <div className="title-header" style={{ borderTopColor: "red" }}>
                                        <h4 className="title">Pharmacognosy</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                                    <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Isolation of crude drugs</li>
                                    <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Plant tissue culture</li>
                                    <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Herbal product preparations</li>
                                </ul>
                            </div>
                            <div className="col-md-6">
                                <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                                    <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Phytochemistry</li>
                                    <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Ethnobotanical studies</li>
                                </ul>
                            </div>
                            <div className="col-md-12">
                                <div className="section-title with-desc text-left clearfix paddingtop-2">
                                    <div className="title-header" style={{ borderTopColor: "red" }}>
                                        <h4 className="title">Pharmaceutics</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                                    <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Formulation and optimization  of nano practical drug delivery system</li>
                                    <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Characterization and optimization NDDS</li>
                                    <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Polymeric nano practical drug delivery system</li>
                                    <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Formulation and evaluation of multi particulate drug delivery system</li>
                                </ul>
                            </div>
                            <div className="col-md-6">
                                <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                                    <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Evaluation and characterization of microspheres</li>
                                    <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Design and evaluation of pulsatile drug delivery system</li>
                                    <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Chrono therapeutic drug delivery system</li>
                                </ul>
                            </div>
                            <div className="col-md-12">
                                <div className="section-title with-desc text-left clearfix paddingtop-2">
                                    <div className="title-header" style={{ borderTopColor: "red" }}>
                                        <h4 className="title">Pharmacy Practice</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                                    <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Comparative study between the diseases or drugs</li>
                                    <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Prescription pattern analysis</li>
                                    <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Prevalence or incidence studies</li>
                                </ul>
                            </div>
                            <div className="col-md-6">
                                <ul className="ttm-list ttm-list-textsize-medium ttm-list-style-icon pt-15">
                                    <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />KAP study</li>
                                    <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Community based cohort studies</li>
                                    <li><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" />Pharmacovigilance</li>
                                </ul>
                            </div>
                            <div className="col-md-12"><br/><br/></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

