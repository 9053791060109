import React, { Component } from 'react';
import { NavLink } from 'react-router-dom'; 

export class Menu extends Component {
    render() {
        return (
            <nav id="menu" role="navigation" className="menu">
                <ul>
                    <li className="menu-hasdropdown"><a>About us</a>
                        <ul className="menu-dropdown">
                            <li><NavLink to='/About-Management'>About Management</NavLink></li>
                            <li><NavLink to='/About-College'>About College</NavLink></li>
                            <li><NavLink to='/About-Chairman'>Chairman Message</NavLink></li>
                            <li><NavLink to='/About-Principal'>Principal’s Desk</NavLink></li>
                            <li><NavLink to='/About-Rules'>Rules &amp; Regulations</NavLink></li>
                            <li><NavLink to='/About-Library'>Facilities</NavLink></li>
                        </ul>
                    </li>
                    <li className="menu-hasdropdown"><a>Programs</a>
                        <ul className="menu-dropdown">
                            <li><NavLink to='/Programs-D-Pharm'>D. Pharm Course</NavLink></li>
                            <li><NavLink to='/Programs-B-Pharm'>B.Pharm Course</NavLink></li>
                            <li><NavLink to='/Programs-Doctor-Pharm'>Doctor of Pharmacy (Pharm D)</NavLink></li>
                            <li><NavLink to='/Programs-M-Pharm'>M.Pharm Course</NavLink></li>
                            <li><NavLink to='/Programs-PHD'>Doctor of Philosophy (PhD)</NavLink></li>
                        </ul>
                    </li>
                    <li className="menu-hasdropdown"><a>Departments</a>
                        <ul className="menu-dropdown">
                            <li><NavLink to='/Departments-Pharmaceutics'>Pharmaceutics</NavLink></li>
                            <li><NavLink to='/Departments-Pharmacology'>Pharmacology</NavLink></li>
                            <li><NavLink to='/Departments-Pharmaceutical-Chemistry'>Pharmaceutical Chemistry</NavLink></li>
                            <li><NavLink to='/Departments-Pharmacognosy'>Pharmacognosy</NavLink></li>
                            <li><NavLink to='/Departments-Pharmacy-Practice'>Pharmacy Practice</NavLink></li>
                        </ul>
                    </li>
                    <li className="menu-hasdropdown"><a>Academics</a>
                        <ul className="menu-dropdown">
                            <li><NavLink to='/Academics-Administrative'>Administrative Staff</NavLink></li>
                            <li><NavLink to='/Alumni'>Alumni</NavLink></li>
                            <li><NavLink to='/Academics-Research'>Research</NavLink></li>
                        </ul>
                    </li>
                    <li className="menu-hasdropdown"><a>Statutory Cell</a>
                        <ul className="menu-dropdown">
                            <li><NavLink to='/Statutory-Student-Grievance'>Student Grievance Redressal Cell</NavLink></li>
                            <li><NavLink to='/Statutory-Anti-Discrimination'>Anti Discrimination Cell</NavLink></li>
                            <li><NavLink to='/Statutory-Anti-Ragging'>Anti Ragging Committee</NavLink></li>
                            <li><NavLink to='/Statutory-Womens-Grievance'>Womens Grievance Cell</NavLink></li>
                            <li><NavLink to='/Statutory-Gender-Sensitization'>Gender Sensitization Cell</NavLink></li>
                            <li><NavLink to='/Statutory-Staff-Grievance'>Staff Grievance Cell</NavLink></li>
                            <li><NavLink to='/Statutory-Placement'>Placement and Career Cell</NavLink></li>
                            <li><NavLink to='/Statutory-SC'>SC / ST Cell</NavLink></li>
                        </ul>
                    </li>
                    <li><NavLink to="/Contact">Contact</NavLink></li>
                </ul>
            </nav>

        )
    }
}