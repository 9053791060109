import React, { Component } from 'react'
import Header from '../layout/Header';
import Banner from '../Common/Banner';
import DepartmentSidebar from './DepartmentSidebar';
import HodDisplay from './HodDisplay';
import NoData from "../Common/NoData";
import Loading from "../Common/Loading";
import ModalImage from "react-modal-image";
import { firestore } from '../../Config/FbConfig';
import { doc, getDoc } from 'firebase/firestore';

export default class Pharmaceutics extends Component {

    state = { Datas: [] }

    async componentDidMount() {
        //For Teaching
        const docRef = doc(firestore, "FacultiesandStaff", "Teaching");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            var Data = []
            Object.entries(docSnap.data()).forEach(([key, value]) => {
                if (value.Department === "Pharmaceutics") {
                    Data.push(Object.assign(
                        {
                            Id: key,
                            Priority: value.Priority != undefined ? value.Priority : "999",
                            Image: value.Image,
                            Name: value.Name,
                            Description: value.Description,
                            Designation: value.Designation,
                            Qualification: value.Qualification,
                            Link: value.Link
                        }))
                }
            });

            Data.length === 0 ? this.setState({ Datas: "NO_999" }) : this.setState({ Datas: Data.sort(function (a, b) { return a.Priority - b.Priority; }) })

        }

        else { this.setState({ Datas: "NO_999" }) }
    }

    render() {
        return (
            <div className="body_wrapper">
                <Header />
                <Banner title="Pharmaceutics" image="departments/Pharmaceutics.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <div className="ttm-service-single-content-area">
                                    <div className="section-title with-desc text-left clearfix">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">About Department</h4>
                                        </div>
                                    </div>
                                    <div className="ttm-service-description">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/departments/department_1.jpg"} alt="Error" />
                                            </div>
                                            <div className="col-md-7" style={{ margin: "auto" }}>
                                                <p className="text-justify">Pharmaceutics is the discipline of pharmacy that deals with the science of dosage form design and includes all facets of the process of turning a chemical entity into a dosage form that can be safely and effectively used by patients in the community. The department has well equipped laboratories with modern facilities to formulate and evaluate different dosage forms.</p>
                                            </div>
                                            <div className="col-md-12 paddingtop-1">
                                                <p className="text-justify">The uniqueness of the department is a team based approach to provide a scientific avenue for students to acquire knowledge from fundamentals of dosage forms to formulation development of various drug delivery systems including its regulatory and pharmacokinetics aspects to meet the standards of pharmaceutical industries. In addition to teaching, faculty members in the Department of Pharmaceutics are actively involved in attending conferences to upgrade their knowledge to meet the current trends and actively participate and conduct research activities. Several research activities are published in highly reputed indexed journals. The students passed out from the department are well placed in various fields such as academics, drug testing laboratories, formulation development, quality assurance, regulatory industries and etc.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <HodDisplay department="Pharmaceutics" />
                                    <div className="section-title with-desc text-left clearfix">
                                        <div className="title-header" style={{ borderTopColor: "red" }}>
                                            <h4 className="title">Our Teaching Faculty</h4>
                                        </div>
                                    </div>
                                    <div className="container">
                                        <div className="row paddingtop-1">
                                            {
                                                this.state.Datas.length === 0 ? <Loading /> :
                                                    this.state.Datas === "NO_999" ? <NoData /> :
                                                        this.state.Datas.map((data, i) => (
                                                            <div className="col-lg-6" key={i}>
                                                                <div className="row">
                                                                    <div className="col-lg-4">
                                                                        <div className="gallery-box">
                                                                            <ModalImage className="swaraimage1" small={data.Image} large={data.Image} alt="Image" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-8" style={{ margin: "auto" }}>
                                                                        <p style={{ color: "black", fontWeight: "bold" }}>{data.Name}</p>
                                                                        <p style={{ color: "black" }}>
                                                                            {data.Qualification}<br />
                                                                            {data.Designation}<br />
                                                                            <a href={data.Link} target="_blank">View Details</a>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <br />
                                                            </div>
                                                        ))
                                            }
                                        </div>
                                        <br />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 widget-area">
                                <DepartmentSidebar />
                            </div>
                        </div>
                    </div>
                </div>
                <br />
            </div>
        )
    }
}
